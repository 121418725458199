<template>
  <div class="flex flex-col items-center justify-center bg-base-200 h-screen overflow-hidden">
    <div class="w-full max-w-5xl mt-0 relative">

      <!-- Display the message for guests with improved font and style -->
      <div class="flex justify-center w-full mt-10">
        <p class="text-2xl text-gray-300 font-bold tracking-wide">
          Message <span class="text-primary">@PixelManagement</span> on Telegram to get access!
        </p>
      </div>

      <!-- Logout Button -->
      <div class="flex justify-end w-full mt-10">
        <button @click="logout" class="btn btn-outline btn-error btn-lg font-semibold tracking-wide">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
import { API_BASE_URL } from '../config.js';

export default {
  methods: {
    logout() {
      // Clear authentication token and user data from localStorage
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');

      // Call the logout API to end the session on the backend
      fetch(`${API_BASE_URL}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include' // To ensure the session is cleared server-side if you're using session-based auth
      })
        .then(response => {
          if (response.ok) {
            // Redirect to login page after successful logout
            this.$router.push('/login');
          } else {
            console.error('Failed to logout');
          }
        })
        .catch(error => {
          console.error('Error during logout:', error);
          // Even if the backend fails, we should still clear the frontend session and redirect
          this.$router.push('/login');
        });
    }
  }
};
</script>

<style scoped>
.logout-btn {
  background-color: #f00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
}

.logout-btn:hover {
  background-color: #c00;
}
</style>
