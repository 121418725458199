<template>
  <div class="flex justify-center items-center h-screen relative">
    <div class="flex flex-col gap-4 rounded-box bg-base-200 p-6 max-w-md w-full">
      <h1 class="text-3xl font-bold self-center">Register</h1>

      <!-- Email Input -->
      <label class="form-control">
        <div class="label">
          <span class="label-text">Email</span>
        </div>
        <input v-model="email" type="email" class="input input-bordered" placeholder="Enter your email" />
      </label>

      <!-- Password Input -->
      <label class="form-control">
        <div class="label">
          <span class="label-text">Password</span>
        </div>
        <input v-model="password" type="password" class="input input-bordered" placeholder="Enter your password" />
      </label>

      <!-- Confirm Password Input -->
      <label class="form-control">
        <div class="label">
          <span class="label-text">Confirm Password</span>
        </div>
        <input v-model="confirmPassword" type="password" class="input input-bordered" placeholder="Confirm your password" />
      </label>

      <!-- Display Success or Error Alert -->
      <div v-if="showAlert" :class="`alert alert-${alertType}`" role="alert">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 shrink-0 stroke-current"
          fill="none"
          viewBox="0 0 24 24">
          <path
            v-if="alertType === 'error'"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          <path
            v-if="alertType === 'success'"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>{{ alertMessage }}</span>
      </div>

      <!-- Register Button -->
      <button class="btn btn-primary" @click="register">Register</button>

      <span class="self-center">
        Already have an account?
        <a class="link link-secondary" @click="goToLogin">Log in</a>
      </span>
    </div>
  </div>
</template>


<script>
import { API_BASE_URL } from '../config.js';

export default {
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      showAlert: false,
      alertType: '',
      alertMessage: ''
    };
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },
    async register() {
      if (!this.validateEmail(this.email)) {
        this.triggerAlert('error', 'Invalid email format');
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.triggerAlert('error', "Passwords don't match");
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/register`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: this.email, password: this.password })
        });

        const data = await response.json();

        if (data.status === 'success') {
          this.triggerAlert('success', 'Registration successful! You can now log in.');
          setTimeout(() => this.$router.push('/login'), 2000);
        } else {
          this.triggerAlert('error', data.message || 'Registration failed');
        }
      } catch (error) {
        console.error('Error during registration:', error);
        this.triggerAlert('error', 'Error during registration. Please try again.');
      }
    },
    triggerAlert(type, message) {
      this.alertType = type;
      this.alertMessage = message;
      this.showAlert = true;

      // Hide the alert after 3 seconds
      setTimeout(() => {
        this.showAlert = false;
      }, 3000);
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<!-- Scoped CSS -->
<style scoped>
.alert {
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: fixed; /* Fixed position to anchor it */
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  max-width: 400px; /* Small and compact like before */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.svg-icon {
  height: 24px;
  width: 24px;
}

</style>
