<template>
  <div class="flex flex-col items-center justify-center bg-base-200 h-screen overflow-hidden">
    <!-- Success alert message -->
    <div v-if="showSuccessAlert" class="fixed top-4 right-4 z-50">
      <div role="alert" class="alert alert-success flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 shrink-0 stroke-current"
          fill="none"
          viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0z" />
        </svg>
        <span>Selected accounts were added to the posting queue!</span>
      </div>
    </div>

    <!-- Main content -->
    <div class="text-center w-full max-w-5xl" style="margin-top: -3rem">
      <select
        v-model="selectedModel"
        @change="onModelChange"
        class="select select-bordered w-full max-w-xs mb-4 bg-base-300 text-white"
      >
        <option disabled value="">Select a model</option>
        <option v-for="(accounts, model) in groupedAccounts" :key="model" :value="model">
          {{ model }}
        </option>
      </select>

      <div v-if="selectedModel" class="flex flex-col flex-grow w-full">
        <div class="table-container">
          <h3 class="text-xl font-semibold text-gray-100 table-title text-center">Accounts for {{ selectedModel }}:</h3>
          <div class="overflow-x-auto">
            <table class="table w-full bg-base-300 rounded-lg">
              <thead class="bg-base-100">
                <tr>
                  <th class="p-3">
                    <input type="checkbox" class="checkbox" @click="toggleSelectAll" />
                  </th>
                  <th class="p-3">Username</th>
                  <th>Email</th>
                  <th>Remaining Posts</th> <!-- New column for remaining posts -->
                  <th>Last Post</th> <!-- New column for Last Post -->
                  <th>Subscription</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(account, index) in paginatedAccounts" :key="index" class="hover:bg-base-100">
                  <td class="p-3">
                    <input type="checkbox" class="checkbox" v-model="selectedAccounts" :value="account" />
                  </td>
                  <td class="px-4 py-2">{{ account.username }}</td>
                  <td>{{ account.email }}</td>
                  <td>{{ getRemainingPosts(account.username) }}</td> <!-- Get remaining posts for each account -->
                  <td>{{ formatTimeAgo(account.lastPost) }}</td> <!-- Display the last post time -->
                  <td>{{ account.subscription_type }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Pagination Controls -->
        <div class="flex justify-center mt-4 mb-10" style="margin-left: 250px">
          <div class="flex items-center justify-center space-x-2">
            <button 
              class="btn btn-outline" 
              @click="prevPage" 
              :disabled="currentPage === 1">
              &lt;
            </button>
            <span class="text-gray-100 mx-2">Page {{ currentPage }} of {{ totalPages }}</span>
            <button 
              class="btn btn-outline" 
              @click="nextPage" 
              :disabled="currentPage === totalPages">
              &gt;
            </button>
          </div>
          <div style="margin-left: 20px">
            <button class="btn btn-primary ml-4" @click="postOnSelectedAccounts">Post on selected accounts</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_BASE_URL } from '../config.js';

export default {
  data() {
    return {
      selectedModel: null,
      groupedAccounts: {},
      selectedAccounts: [],
      currentPage: 1,
      itemsPerPage: 10,
      remainingPostsData: [], // Added this to store remaining posts
      showSuccessAlert: false, // Added for showing success message
    };
  },
  computed: {
    paginatedAccounts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.groupedAccounts[this.selectedModel]?.slice(start, end) || [];
    },
    totalPages() {
      return Math.ceil((this.groupedAccounts[this.selectedModel]?.length || 0) / this.itemsPerPage);
    },
  },
  methods: {
    fetchAccounts() {
      console.log('Fetching accounts...'); // Debugging log

      fetch(`${API_BASE_URL}/api/data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Data fetched:', data); // Log the full response
          this.groupedAccounts = data;
        })
        .catch(error => console.error('Error fetching accounts:', error));
    },
    fetchRemainingPostsForModel(model) {
      fetch(`${API_BASE_URL}/api/remaining_posts?model=${model}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Remaining posts data:', data); // Log the fetched remaining posts
        this.remainingPostsData = data;  // Update the Vue state with the fetched data
      })
      .catch(error => console.error('Error fetching remaining posts:', error));
    },
    getRemainingPosts(username) {
      const postData = this.remainingPostsData.find(post => post.username === username);
      return postData ? postData.remaining_posts : 'N/A';
    },
    formatTimeAgo(lastPost) {
  if (!lastPost || lastPost === "N/A") {
    return 'No posts yet'; // Handle the case where no post is available
  }

  // Ensure lastPost is an integer timestamp (in seconds)
  const postDate = new Date(lastPost * 1000);  // Multiply by 1000 to convert seconds to milliseconds
  const now = new Date();

  // Check if postDate is invalid
  if (isNaN(postDate.getTime())) {
    return 'Invalid time'; // Handle invalid date format
  }

  const secondsAgo = Math.floor((now - postDate) / 1000);

  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`;
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600);
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else {
    const daysAgo = Math.floor(secondsAgo / 86400);
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
              }
    },
    onModelChange() {
      this.currentPage = 1; // Reset to the first page whenever the model changes
      this.fetchRemainingPostsForModel(this.selectedModel); // Fetch remaining posts for the selected model
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        this.selectedAccounts = this.groupedAccounts[this.selectedModel].slice();
      } else {
        this.selectedAccounts = [];
      }
    },
    postOnSelectedAccounts() {
      const queueItems = this.selectedAccounts.map(account => ({
        username: account.username,
        model: this.selectedModel,
        postText: '',  // Placeholder for post text, could be input by user
        status: 'Pending',
      }));

      fetch(`${API_BASE_URL}/api/queue`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(queueItems)
      })
        .then(response => response.json())
        .then(data => {
          console.log('Queue items added:', data);
          this.showSuccessAlert = true;  // Show the success message
          this.fetchRemainingPostsForModel(this.selectedModel); // Refetch remaining posts after posting

          // Hide the success alert after 3 seconds
          setTimeout(() => {
            this.showSuccessAlert = false;
          }, 3000);
        })
        .catch(error => console.error('Error adding queue items:', error));
    },
  },
  mounted() {
    this.fetchAccounts();
  },
};
</script>

<style scoped>
.table-container {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 0 20px;
}

.table-title {
  background-color: #2D3748;
  padding: 10px 15px;
  border-radius: 8px 8px 0 0;
  color: #E5E7EB;
}

.text-center {
  text-align: center;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
}

.h-screen {
  height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}
</style>
