<template>
  <div class="flex flex-col items-center justify-center bg-base-200 h-screen overflow-hidden">
    <div class="w-full max-w-5xl mt-0 relative">
      <h3 class="text-xl font-semibold text-gray-100 table-title text-center mx-auto">Admin Dashboard</h3>

      <!-- User List -->
      <div class="table-container overflow-x-auto mt-2 mx-auto">
        <table class="table w-full bg-base-300 rounded-lg">
          <thead class="bg-base-100">
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Models</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.email" class="hover:bg-base-100">
              <td>{{ user.email }}</td>
              <td>
                <select v-model="user.pendingRole" class="role-select">
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                  <option value="guest">Guest</option>
                </select>
              </td>
              <td class="models-list">{{ user.selectedModels.length > 0 ? user.selectedModels.join(', ') : 'No models selected' }}</td>
              <td>
                <!-- Select Models Button -->
                <button @click="openModal(user)" class="btn btn-outline">Select Models</button>
                <button class="btn btn-success ml-4" @click="saveChanges(user)">Save</button>
                <button class="btn btn-error ml-4" @click="confirmDeleteUser(user)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal for Model Selection -->
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal-content bg-base-300 text-gray-100 rounded-lg p-6">
        <h2 class="text-lg font-semibold mb-2 text-gray-100">Select Models for {{ selectedUser.email }}</h2>

        <div class="model-list">
          <!-- List of models with pagination controls -->
          <div class="overflow-x-auto">
            <table class="table w-full bg-base-300 rounded-lg">
              <thead class="bg-base-100">
                <tr>
                  <th class="p-3">
                    <input type="checkbox" class="checkbox" @click="toggleSelectAllModels" />
                  </th>
                  <th class="p-3">Model Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(model, index) in paginatedModels" :key="index" class="hover:bg-base-100">
                  <td class="p-3">
                    <input type="checkbox" class="checkbox" v-model="selectedUser.selectedModels" :value="model" />
                  </td>
                  <td>{{ model }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Pagination Controls for Model List -->
        <div class="flex justify-center mt-4 mb-10">
          <div class="flex items-center justify-center space-x-2">
            <button class="btn btn-outline" @click="prevModelPage" :disabled="currentModelPage === 1">
              &lt;
            </button>
            <span class="text-gray-100 mx-2">Page {{ currentModelPage }} of {{ totalModelPages }}</span>
            <button class="btn btn-outline" @click="nextModelPage" :disabled="currentModelPage === totalModelPages">
              &gt;
            </button>
          </div>
        </div>

        <div class="modal-actions">
          <button class="btn btn-success" @click="saveSelectedModels">Save</button>
          <button class="btn btn-outline" @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="isDeleteModalOpen" class="modal-overlay">
      <div class="modal-content bg-base-300 text-gray-100 rounded-lg p-6">
        <h2 class="text-lg font-semibold mb-2 text-gray-100">Are you sure you want to delete {{ selectedUser.email }}?</h2>
        <div class="modal-actions">
          <button class="btn btn-error" @click="deleteUser(selectedUser)">Yes, Delete</button>
          <button class="btn btn-outline" @click="closeDeleteModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_BASE_URL } from '../config.js';

export default {
  data() {
    return {
      users: [], // Users list from the database
      models: [], // Models fetched from the Airtable API
      isModalOpen: false, // Controls the visibility of the modal
      isDeleteModalOpen: false, // Controls the visibility of the delete confirmation modal
      selectedUser: null, // The user currently being modified
      currentModelPage: 1,
      itemsPerPage: 5,
    };
  },
  computed: {
    paginatedModels() {
      const start = (this.currentModelPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.models.slice(start, end);
    },
    totalModelPages() {
      return Math.ceil(this.models.length / this.itemsPerPage);
    },
  },
  methods: {
    fetchUsers() {
      fetch(`${API_BASE_URL}/api/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}` // Include the token for authentication
        }
      })
        .then(response => response.json())
        .then(data => {
          this.users = data.map(user => {
            user.selectedModels = user.models || [];
            user.pendingRole = user.role; // Store the current role in a temporary variable
            return user;
          });
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    },
    fetchModels() {
      fetch(`${API_BASE_URL}/api/models`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}` // Include the token for authentication
        }
      })
        .then(response => response.json())
        .then(data => {
          this.models = data;
        })
        .catch(error => {
          console.error('Error fetching models:', error);
        });
    },
    saveChanges(user) {
      // Save the role and models for the user when the "Save" button is clicked
      this.updateUserRole(user).then(() => this.saveModelAccess(user));
    },
    updateUserRole(user) {
      return fetch(`${API_BASE_URL}/api/update_role`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ email: user.email, role: user.pendingRole }), // Use pendingRole instead of role
      })
        .then(response => response.json())
        .then(data => {
          console.log('Role updated:', data);
          user.role = user.pendingRole; // Update the user's role with the selected one after save
        })
        .catch(error => {
          console.error('Error updating role:', error);
        });
    },
    saveModelAccess(user) {
  // Only proceed if there are selected models, otherwise return silently
  if (!user.selectedModels || user.selectedModels.length === 0) {
    console.log(`No models selected for ${user.email}, skipping model save.`);
    return;
  }

  // Save the selected models to the backend for specific users
  return fetch(`${API_BASE_URL}/api/assign_model_access`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`, // User's JWT token
    },
    body: JSON.stringify({
      email: user.email,  // Use user's email as the identifier
      models: user.selectedModels  // Send the selected models
    }),
  })
  .then(response => response.json())
  .then(data => {
    if (data.status === 'success') {
      console.log('Access granted:', data);
    } else {
      console.error('Failed to update access:', data.message);
      alert(`Failed to save models: ${data.message}`); // You can remove this alert if you don't want any warning
    }
  })
  .catch(error => {
    console.error('Error granting access:', error);
        });
    },
    openModal(user) {
      this.selectedUser = user;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    saveSelectedModels() {
      this.closeModal();
    },
    prevModelPage() {
      if (this.currentModelPage > 1) {
        this.currentModelPage--;
      }
    },
    nextModelPage() {
      if (this.currentModelPage < this.totalModelPages) {
        this.currentModelPage++;
      }
    },
    toggleSelectAllModels(event) {
      if (event.target.checked) {
        this.selectedUser.selectedModels = this.models.slice();
      } else {
        this.selectedUser.selectedModels = [];
      }
    },
    confirmDeleteUser(user) {
      this.selectedUser = user;
      this.isDeleteModalOpen = true;
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
    },
    deleteUser(user) {
      // Delete the user and their assigned models
      fetch(`${API_BASE_URL}/api/delete_user`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ email: user.email }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            console.log(`User ${user.email} deleted.`);
            this.users = this.users.filter(u => u.email !== user.email);
            this.closeDeleteModal();
          } else {
            console.error('Failed to delete user:', data.message);
            alert(`Failed to delete user: ${data.message}`);
          }
        })
        .catch(error => {
          console.error('Error deleting user:', error);
        });
    },
  },
  mounted() {
    this.fetchUsers();
    this.fetchModels();
  }
};
</script>


<style scoped>
.table-container {
  max-width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}

.table-title {
  background-color: #2d3748;
  padding: 10px 1px;
  border-radius: 8px 8px 0 0;
  color: #e5e7eb;
  width: calc(100% - 40px);
  margin: 0 auto;
  margin-top: -40px;
  margin-bottom: -10px;
}

.role-select {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #1a202c;
  color: #e5e7eb;
  border: none;
}

.models-list {
  white-space: normal; /* Allow the text to wrap */
  word-break: break-word; /* Ensure long words or lists break correctly */
  max-width: 250px; /* You can adjust this width as needed */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #1a202c;
  color: #e5e7eb;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
}

.model-list {
  display: flex;
  flex-direction: column;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.checkbox {
  background-color: #2d3748;
}
</style>
