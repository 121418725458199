<template>
    <div class="flex flex-col items-center justify-center bg-base-200 h-screen overflow-hidden">
        <div class="w-full max-w-5xl mt-0 relative">
            <h3 class="text-xl font-semibold text-gray-100 table-title text-center mx-auto">Posting Queue</h3>

            <div class="table-container overflow-x-auto mt-2 mx-auto">
                <table class="table w-full bg-base-300 rounded-lg">
                    <thead class="bg-base-100">
                        <tr>
                            <th class="p-3">
                                <label class="cursor-pointer">
                                    <input type="checkbox" @change="toggleSelectAll" :checked="isAllSelected" class="checkbox checkbox-m" />
                                </label>
                            </th>
                            <th class="p-3" style="width: 5%;">Queue</th>
                            <th class="p-3" style="width: 20%;">Username</th>
                            <th class="p-3 text-center" style="width: 15%;">Model</th>
                            <th class="p-3" style="width: 30%;">Post Description</th>
                            <th class="p-3 text-center" style="width: 10%;">Pin</th>
                            <th class="p-3 text-center" style="width: 10%;">Login Status</th>
                            <th class="p-3 text-center" style="width: 10%;">Post Status</th>
                            <th class="p-3 text-center action-column" style="width: 10%;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in paginatedQueue" :key="index" class="hover:bg-base-100">
                            <td class="p-3">
                                <label class="cursor-pointer">
                                    <input type="checkbox" v-model="selectedItems" :value="item" class="checkbox checkbox-m" />
                                </label>
                            </td>
                            <td class="p-3">{{ startIndex + index + 1 }}</td>
                            <td class="px-4 py-2">{{ item.username }}</td>
                            <td class="px-4 py-2">
                                <div class="flex items-center justify-center">
                                    <img :src="getModelIcon(item.model)" alt="model icon" class="model-icon" />
                                    <span class="model-name">{{ item.model }}</span>
                                </div>
                            </td>
                            <td>
                                <textarea 
                                    placeholder="Bio" 
                                    class="textarea textarea-bordered textarea-xs w-full max-w-xs" 
                                    v-model="item.postText"
                                    @input="updatePostText(item.id, item.postText)"
                                ></textarea>
                            </td>
                            <td class="text-center">
                                <input type="checkbox" v-model="item.shouldPin" class="checkbox checkbox-m" />
                            </td>
                            <td class="text-center">
                                <button v-if="item.loginStatus === 'Success'" class="btn btn-outline btn-success btn-sm">Success</button>
                                <button v-else class="btn btn-outline btn-sm custom-btn">{{ item.loginStatus }}</button>
                            </td>
                            <td class="text-center">
                                <button v-if="item.postStatus === 'Success'" class="btn btn-outline btn-success btn-sm">Success</button>
                                <button v-else-if="item.postStatus === 'No Posts'" class="btn btn-outline btn-error btn-sm">No Posts</button>
                                <button v-else class="btn btn-outline btn-sm custom-btn">{{ item.postStatus }}</button>
                            </td>
                            <td class="text-center action-column">
                                <button class="btn btn-outline btn-success btn-sm" @click="testLogin(item)">Login</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Control Hub -->
            <div class="flex justify-end mt-2 space-x-2">
                <button class="btn btn-outline btn-error btn-sm" @click="deleteSelected" :disabled="!selectedItems.length">
                    Delete Selected
                </button>
                <button class="btn btn-outline btn-primary btn-sm" @click="generateSpintax">
                    Generate Post Descriptions
                </button>
                <button class="btn btn-outline btn-success btn-sm" @click="startPosting">
                    Start Posting
                </button>
                <button class="btn btn-outline btn-info btn-sm ml-2" @click="archivePosts">
                    Archive Successful Posts
                </button>
            </div>

            <div class="flex justify-center mt-2 mb-10">
                <div class="flex items-center justify-center space-x-2">
                    <button class="btn btn-outline" @click="prevPage" :disabled="currentPage === 1">
                        &lt;
                    </button>
                    <span class="text-gray-100 mx-2">Page {{ currentPage }} of {{ totalPages }}</span>
                    <button class="btn btn-outline" @click="nextPage" :disabled="currentPage === totalPages">
                        &gt;
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getModelIcon } from '@/utils/model-icons';
import { API_BASE_URL } from '../config.js';

export default {
    data() {
        return {
            queue: [],
            currentPage: 1,
            itemsPerPage: 8,
            selectedItems: [],
        };
    },
    computed: {
        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },
        paginatedQueue() {
            return this.queue.slice(this.startIndex, this.startIndex + this.itemsPerPage);
        },
        totalPages() {
            return Math.ceil(this.queue.length / this.itemsPerPage);
        },
        isAllSelected() {
            return this.selectedItems.length === this.queue.length;
        },
    },
    methods: {
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        getModelIcon(model) {
            return getModelIcon(model);
        },
        toggleSelectAll() {
            if (this.isAllSelected) {
                this.selectedItems = [];
            } else {
                this.selectedItems = [...this.queue];
            }
        },
        deleteSelected() {
            const itemsToDelete = this.selectedItems.map(item => ({
                username: item.username,
            }));

            fetch(`${API_BASE_URL}/api/queue`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(itemsToDelete),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Queue items deleted:', data);
                    this.queue = this.queue.filter(item => !this.selectedItems.includes(item));
                    this.selectedItems = [];
                })
                .catch(error => console.error('Error deleting queue items:', error));
        },
        generateSpintax() {
    const token = localStorage.getItem('token');  // Include token in headers
    fetch(`${API_BASE_URL}/api/queue/spintax`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,  // Ensure token is passed
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data.status === 'spintax_generated') {
            console.log('Spintax generated:', data);
            this.refreshQueue();  // Refresh queue after generating descriptions
        } else {
            console.error('Error generating spintax:', data);
        }
    })
    .catch(error => console.error('Error generating spintax:', error));
        },
        updatePostText(id, postText) {
            fetch(`${API_BASE_URL}/api/queue/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ postText }),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Post text updated:', data);
                })
                .catch(error => console.error('Error updating post text:', error));
        },
        
        refreshQueue() {
    const token = localStorage.getItem('token');  // Ensure token is included in the request headers

    fetch(`${API_BASE_URL}/api/queue`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        this.queue = data.map(item => {
            item.shouldPin = true;  // Set the pin checkbox to be checked by default
            return item;
        });
    })
    .catch(error => console.error('Error fetching queue:', error));
        },
        
        async startPosting() {
            for (const item of this.selectedItems) {
                item.loginStatus = 'Logging in...';
                const loginSuccess = await this.loginAndUpdateStatus(item);
                if (loginSuccess) {
                    item.postStatus = 'Posting...';
                    await this.postAndUpdateStatus(item);
                }
            }
        },
        async loginAndUpdateStatus(item) {
            try {
                const response = await fetch(`${API_BASE_URL}/api/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username: item.username }),
                });
                const data = await response.json();
                if (data.status === 'success') {
                    item.loginStatus = 'Success';
                    return true;
                } else {
                    item.loginStatus = 'Failed';
                    console.error('Login failed: ' + data.message);
                    return false;
                }
            } catch (error) {
                console.error('Error logging in:', error);
                item.loginStatus = 'Error';
                return false;
            }
        },
        async postAndUpdateStatus(item) {
            try {
                const response = await fetch(`${API_BASE_URL}/api/start_posting`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ queue_items: [item] }),
                });
                const data = await response.json();
                if (data.status === 'success') {
                    const updatedQueueItem = await this.refreshQueueItem(item.id);
                    if (updatedQueueItem) {
                        item.postStatus = updatedQueueItem.postStatus;
                    }
                } else {
                    item.postStatus = 'Failed';
                    console.error('Failed to start posting: ' + data.message);
                }
            } catch (error) {
                console.error('Error starting posting:', error);
                item.postStatus = 'Error';
            }
        },
        async testLogin(item) {
            item.loginStatus = 'Logging in...';
            await this.loginAndUpdateStatus(item);
        },
        async refreshQueueItem(id) {
            try {
                const response = await fetch(`${API_BASE_URL}/api/queue`);
                const queueData = await response.json();
                return queueData.find(queueItem => queueItem.id === id);
            } catch (error) {
                console.error('Error fetching queue item:', error);
                return null;
            }
        },
        async archiveSuccessfulPosts() {
            try {
                const response = await fetch(`${API_BASE_URL}/api/archive_successful_posts`, {
                    method: 'POST',
                });
                const data = await response.json();
                if (data.status === 'success') {
                    console.log('Successfully archived posts');
                    // Refresh the queue to remove the archived posts from the UI
                    this.refreshQueue();
                } else {
                    console.error('Failed to archive posts:', data.message);
                }
            } catch (error) {
                console.error('Error archiving posts:', error);
            }
        },
        async archivePosts() {
            try {
                const response = await fetch(`${API_BASE_URL}/api/archive_successful_posts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                if (data.status === 'success') {
                    console.log(data.message);
                    this.refreshQueue();  // Refresh the queue to remove the archived posts from the view
                } else {
                    console.error('Failed to archive posts: ' + data.message);
                }
            } catch (error) {
                console.error('Error archiving posts:', error);
            }
        },
    



    },
    created() {
        this.refreshQueue();
    },
};
</script>

<style scoped>
.table-container {
    max-width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}

.table-title {
    background-color: #2d3748;
    padding: 10px 1px;
    border-radius: 8px 8px 0 0;
    color: #e5e7eb;
    width: calc(100% - 40px);
    margin: 0 auto;
    margin-top: -40px;
    margin-bottom: -10px;
}

.custom-btn {
    padding: 4px 8px;  /* Increase the padding to make the button larger */
    font-size: 12px;
    height: auto;
    min-width: auto;
}

.btn-error {
    border-color: #f44336; /* Red outline */
    color: #f44336;        /* Red text color */
}

.model-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.model-name {
    margin-left: 8px;
}

.flex {
    justify-content: center;
    align-items: center;
}

.action-column {
    border-left: 2px solid #2d3748; /* Separator line */
    background-color: #1a202c; /* Slightly darker background */
    padding-left: 10px; /* Add some padding to separate the action buttons */
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.h-screen {
    height: 100vh;
}

.overflow-hidden {
    overflow: hidden;
}
</style>
