<template>
  <div class="flex flex-col items-center justify-center bg-base-200 h-screen overflow-hidden">
    <div v-if="loading" class="text-center">
      <span class="loading loading-dots loading-lg"></span>
    </div>
    <div v-else class="text-center w-full max-w-5xl" style="margin-top: -5rem">
      <div class="table-container">
        <table class="table w-full bg-base-300 rounded-lg">
          <!-- head -->
          <thead class="bg-base-100">
            <tr>
              <th>Model</th>
              <th>Accounts</th>
              <th>Posts</th>
            </tr>
          </thead>
          <tbody>
            <!-- Loop through paginatedAccounts to display each model name and account data -->
            <tr v-for="([modelName, accounts], index) in paginatedAccounts" :key="index">
              <td>
                <div class="flex items-center gap-3">
                  <div class="avatar">
                    <div class="mask mask-squircle h-12 w-12">
                      <img :src="getModelIcon(modelName)" alt="Model Avatar" />
                    </div>
                  </div>
                  <div>
                    <div class="font-bold">{{ modelName }}</div> <!-- This will now display the correct model name -->
                  </div>
                </div>
              </td>
              <td>{{ accounts.length }}</td> <!-- Number of accounts -->
              <td>{{ accounts.reduce((sum, acc) => sum + (acc.posts || 0), 0) }}</td> <!-- Sum of posts -->
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination Controls -->
      <div class="flex justify-center mt-2 mb-4">
        <div class="flex items-center justify-center space-x-2">
          <button 
            class="btn btn-outline" 
            @click="prevPage" 
            :disabled="currentPage === 1">
            &lt;
          </button>
          <span class="text-gray-100 mx-2">Page {{ currentPage }} of {{ totalPages }}</span>
          <button 
            class="btn btn-outline" 
            @click="nextPage" 
            :disabled="currentPage === totalPages">
            &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getModelIcon } from '@/utils/model-icons'; // Import getModelIcon function
import { API_BASE_URL } from '../config.js';

export default {
  data() {
    return {
      groupedAccounts: {}, // Contains models and their associated accounts
      loading: true,
      currentPage: 1,
      itemsPerPage: 8,
    };
  },
  computed: {
  paginatedAccounts() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    
    // Ensure `groupedAccounts` is properly converted into an array of [modelName, accounts] pairs
    return Object.entries(this.groupedAccounts).slice(start, end);
  },
  totalPages() {
    return Math.ceil(Object.keys(this.groupedAccounts).length / this.itemsPerPage);
    },
  },
  methods: {
    fetchUserModels() {
      fetch(`${API_BASE_URL}/api/data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // User's token for authentication
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.groupedAccounts = data; // Set the models the user has access to
          this.loading = false; // Stop the loading spinner after data is fetched
        })
        .catch((error) => {
          console.error('Error fetching models:', error);
          this.loading = false; // Stop the loading spinner even if there's an error
        });
    },
    getModelIcon(modelName) {
      return getModelIcon(modelName); // Dynamically fetch the image for the model
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  },
  mounted() {
    this.loading = true; // Ensure loading is set to true initially
    this.fetchUserModels(); // Fetch models for the logged-in user
  },
};
</script>


<style scoped>
.table-container {
  max-height: calc(100vh - 150px); /* Consistent height adjustment */
  overflow-y: auto;
  padding: 0 20px; /* Padding added to ensure it doesn't hit the screen edges */
}

.table {
  margin-top: 10px; /* Keep margin similar to other file */
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.avatar img {
  border-radius: 50%;
  object-fit: cover;
}

.text-center {
  text-align: center;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.h-screen {
  height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}

.text-center .loading-dots {
  color: white;
}
</style>
