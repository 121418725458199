import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PostView from '../views/PostView.vue';
import PostingQueue from '../views/PostingQueue.vue';
import ArchivedPosts from '../views/ArchivedPosts.vue';
import LoginView from '../views/LoginView.vue';
import AdminView from '../views/AdminView.vue';
import GuestView from '../views/GuestView.vue';
import RegisterView from '../views/RegisterView.vue';
import ForgotPassword from '../views/ForgotPassword.vue';

const routes = [
  { path: '/', component: HomeView, meta: { requiresAuth: true, role: 'user' } },  // HomeView as the default for users
  { path: '/posting-queue', component: PostingQueue, meta: { requiresAuth: true, role: 'user' } },
  { path: '/archived-posts', component: ArchivedPosts, meta: { requiresAuth: true, role: 'user' } },
  { path: '/post', component: PostView, meta: { requiresAuth: true, role: 'user' } },
  { path: '/login', component: LoginView },  // No auth required
  { path: '/register', component: RegisterView },  // No auth required
  { path: '/admin', component: AdminView, meta: { requiresAuth: true, role: 'admin' } },
  { path: '/guest', component: GuestView, meta: { requiresAuth: true, role: 'guest' } },  // Guest route
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/:pathMatch(.*)*', redirect: '/' },  // Redirect to HomeView by default
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global Navigation Guard with JWT Token check
router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  if (to.path === '/login' || to.path === '/register') {
    // Allow access to login and register pages
    return next();
  }

  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // If no token, redirect to login
    if (!token) {
      return next('/login');
    }

    // If user is an admin, allow access to all routes
    if (user.access_level === 'admin') {
      return next();
    }

    // If user exists but their role doesn't match the route's role, redirect
    if (to.meta.role && user.access_level !== to.meta.role) {
      if (user.access_level === 'user' && to.path !== '/') {
        return next('/');
      } else if (user.access_level === 'guest' && to.path !== '/guest') {
        return next('/guest');
      }
    }
  }

  next(); // Proceed as normal
});

export default router;
