<template>
    <div class="flex flex-col items-center justify-center bg-base-200 h-screen overflow-hidden">
        <div class="w-full max-w-5xl mt-0 relative">
            <h3 class="text-xl font-semibold text-gray-100 table-title text-center mx-auto">Archived Posts</h3>

            <div class="table-container overflow-x-auto mt-2 mx-auto">
                <table class="table w-full bg-base-300 rounded-lg">
                    <thead class="bg-base-100">
                        <tr>
                            <th class="p-3">Queue</th>
                            <th class="p-3" style="width: 20%;">Username</th>
                            <th class="p-3 text-center" style="width: 15%;">Model</th>
                            <th class="p-3" style="width: 30%;">Post Description</th>
                            <th class="p-3 text-center" style="width: 15%;">Archived At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in paginatedArchivedPosts" :key="index" class="hover:bg-base-100">
                            <td class="p-3">{{ startIndex + index + 1 }}</td>
                            <td class="px-4 py-2">{{ item.username }}</td>
                            <td class="px-4 py-2">
                                <div class="flex items-center justify-center">
                                    <!-- If you need to show an icon, ensure the function is correctly defined -->
                                    <img :src="getModelIcon(item.model)" alt="model icon" class="model-icon" />
                                    <span class="model-name">{{ item.model }}</span>
                                </div>
                            </td>
                            <td>{{ item.postText }}</td>
                            <td class="text-center">{{ item.archived_at }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="flex justify-center mt-2 mb-10">
                <div class="flex items-center justify-center space-x-2">
                    <button class="btn btn-outline" @click="prevPage" :disabled="currentPage === 1">
                        &lt;
                    </button>
                    <span class="text-gray-100 mx-2">Page {{ currentPage }} of {{ totalPages }}</span>
                    <button class="btn btn-outline" @click="nextPage" :disabled="currentPage === totalPages">
                        &gt;
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getModelIcon } from '@/utils/model-icons';
import { API_BASE_URL } from '../config.js';

export default {
    data() {
        return {
            archivedPosts: [],
            currentPage: 1,
            itemsPerPage: 10,
        };
    },
    computed: {
        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },
        paginatedArchivedPosts() {
            return this.archivedPosts.slice(this.startIndex, this.startIndex + this.itemsPerPage);
        },
        totalPages() {
            return Math.ceil(this.archivedPosts.length / this.itemsPerPage);
        }
    },
    methods: {
        fetchArchivedPosts() {
        const token = localStorage.getItem('token');  // Include token in headers for authentication
        fetch(`${API_BASE_URL}/api/archived_posts`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,  // Send the JWT token in the Authorization header
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if (Array.isArray(data)) {
                this.archivedPosts = data;
            } else {
                console.error('Archived posts response is not an array:', data);
            }
        })
        .catch(error => console.error('Error fetching archived posts:', error));
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        getModelIcon(model) {
            return getModelIcon(model);
        },
    },
    created() {
        this.fetchArchivedPosts();
    }
};
</script>

<style scoped>
.table-container {
    max-width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}

.table-title {
    background-color: #2d3748;
    padding: 10px 1px;
    border-radius: 8px 8px 0 0;
    color: #e5e7eb;
    width: calc(100% - 40px);
    margin: 0 auto;
    margin-top: -40px;
    margin-bottom: -10px;
}

.model-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.model-name {
    margin-left: 8px;
}

.flex {
    justify-content: center;
    align-items: center;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.h-screen {
    height: 100vh;
}

.overflow-hidden {
    overflow: hidden;
}
</style>
