export const modelIcons = {
    Ashley: '/ashley.jpg',
    Gleiz: '/gleiz.jpg',
    Lucy: '/lucy.jpg',
    Luna: '/luna.jpg',
    Nina: '/nina.jpg',
    Nixi: '/nixi.jpg',
    'Organic - Babes': '/babes.jpg',
    Runa: '/runa.jpg',
    Valerie: '/valerie.jpg',
    NixiPixie: '/nixipixie.jpg',
    // Add other model-image mappings here
  };
  
  export function getModelIcon(model) {
    return modelIcons[model] || '/default.jpg';
  }