<template>
  <nav class="navbar justify-center bg-base-300">
    <ul class="menu menu-horizontal flex-nowrap overflow-x-auto">
      <li>
        <router-link to="/" class="btn primary">
          Home
        </router-link>
      </li>
      <li>
        <router-link to="/post" class="btn primary">
          Post
        </router-link>
      </li>
      <li>
        <router-link to="/posting-queue" class="btn primary">
          Posting Queue
        </router-link>
      </li>
      <li>
        <router-link to="/archived-posts" class="btn primary">
          Archived Posts
        </router-link>
      </li>

      <!-- Conditionally render the Admin button -->
      <li v-if="isAdmin">
        <router-link to="/admin" class="btn primary">
          Admin
        </router-link>
      </li>

      <div class="flex justify-end w-full">
        <button @click="logout" class="btn btn-outline btn-error btn-m font-semibold tracking-wide">Logout</button>
      </div>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TopNavBar',
  data() {
    return {
      isAdmin: false,
    };
  },
  mounted() {
    // Get the 'user' object from localStorage
    const user = JSON.parse(localStorage.getItem('user'));

    // Check if the access_level is 'admin'
    if (user && user.access_level === 'admin') {
      this.isAdmin = true;  // Show admin button if the user is an admin
    }
  },
  methods: {
    logout() {
      // Clear authentication token and user data from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('user');  // Clear the user data

      // Call the logout API to end the session on the backend
      fetch('http://localhost:5001/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include' // To ensure the session is cleared server-side if you're using session-based auth
      })
      .then(response => {
        if (response.ok) {
          // Redirect to login page after successful logout
          this.$router.push('/login');
        } else {
          console.error('Failed to logout');
        }
      })
      .catch(error => {
        console.error('Error during logout:', error);
        // Even if the backend fails, we should still clear the frontend session and redirect
        this.$router.push('/login');
      });
    }
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.menu-horizontal {
  display: flex;
  flex-direction: row;
}

.menu-horizontal li {
  margin-right: 20px;
}

a.active {
  color: #3b82f6;
  font-weight: bold;
}

.bg-gray-700 {
  background-color: #4B5563; /* Slightly lighter background when active */
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}
</style>
