<template>
  <div id="app">
    <!-- Only show the TopNavBar if the user is authenticated and not a guest -->
    <TopNavBar v-if="isAuthenticated && userRole !== 'guest'" />
    <router-view></router-view>
  </div>
</template>

<script>
import TopNavBar from './components/Topbar.vue';

// Function to decode the JWT and get the expiration time
function decodeJWT(token) {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload;
}

export default {
  data() {
    return {
      isAuthenticated: false, // Check if the user is logged in
      userRole: null, // Track the user's role
      tokenExpiry: null, // Store token expiry time
    };
  },
  components: {
    TopNavBar,
  },
  mounted() {
    this.checkAuth();
    this.setupGlobalFetch(); // Set up the global fetch override
  },
  methods: {
    checkAuth() {
      const authToken = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      if (authToken && user) {
        const decoded = decodeJWT(authToken);
        this.tokenExpiry = decoded.exp * 1000; // Convert expiry time to milliseconds

        if (Date.now() >= this.tokenExpiry) {
          this.logout();  // Token expired, force logout
        } else {
          this.isAuthenticated = true;
          this.userRole = user.access_level;
        }
      } else {
        this.isAuthenticated = false;
        this.userRole = null;
      }
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.isAuthenticated = false;
      this.$router.push('/login');
    },
    setupGlobalFetch() {
      const originalFetch = window.fetch;

      window.fetch = async (url, options = {}) => {
        const token = localStorage.getItem('token'); // Retrieve the token
        if (!options.headers) {
          options.headers = {};
        }

        // Add the Authorization header if token exists
        if (token) {
          options.headers['Authorization'] = `Bearer ${token}`;
        }

        // Set content type to application/json by default
        if (!options.headers['Content-Type']) {
          options.headers['Content-Type'] = 'application/json';
        }

        // Proceed with the original fetch function
        return originalFetch(url, options);
      };
    }
  },
  watch: {
    $route() {
      this.checkAuth(); // Re-check authentication on route change
    },
  },
};
</script>

<style scoped>
/* Add your styles here if needed */
</style>
