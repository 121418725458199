<template>
  <div class="flex justify-center items-center h-screen">
    <div class="flex flex-col gap-4 rounded-box bg-base-200 p-6 max-w-md w-full">
      <h1 class="text-3xl font-bold self-center">Log in</h1>

      <span class="self-center">
        Don't have an account?
        <a class="link link-secondary" @click="goToRegister">Register</a>
      </span>

      <div class="divider">OR</div>

      <label class="form-control">
        <div class="label">
          <span class="label-text">Email</span>
        </div>
        <input v-model="email" type="email" class="input input-bordered" placeholder="Enter your email" />
      </label>

      <label class="form-control">
        <div class="label">
          <span class="label-text">Password</span>
          <a class="label-text link link-accent" @click="goToForgotPassword">Forgot password?</a>
        </div>
        <input v-model="password" type="password" class="input input-bordered" placeholder="Enter your password" />
      </label>

      <div class="form-control">
        <label class="cursor-pointer label self-start gap-2">
          <input type="checkbox" class="checkbox" />
          <span class="label-text">Remember me</span>
        </label>
      </div>

      <button class="btn btn-primary" @click="login">Log in</button>

      <p v-if="errorMessage" class="text-red-500">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { API_BASE_URL } from '../config.js';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',  // Variable for handling error messages
    };
  },
  methods: {
    async login() {
      // Basic validation before sending the request
      if (!this.email || !this.password) {
        this.errorMessage = 'Please enter both email and password.';
        return;
      }
      this.errorMessage = '';  // Reset the error message on successful input

      try {
        const response = await fetch(`${API_BASE_URL}/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: this.email, password: this.password }),
        });

        const data = await response.json();

        if (data.status === 'success') {
          // Store the JWT token in localStorage
          localStorage.setItem('token', data.token);

          const user = { email: this.email, access_level: data.role };
          localStorage.setItem('user', JSON.stringify(user));

          // Redirect based on access level
          if (data.role === 'admin') {
            this.$router.push('/');
          } else if (data.role === 'user') {
            this.$router.push('/');
          } else if (data.role === 'guest') {
            this.$router.push('/guest');
          }
        } else {
          this.errorMessage = data.message || 'Login failed';
        }
      } catch (error) {
        console.error('Error during login:', error);
        this.errorMessage = 'Error during login. Please try again.';
      }
    },
    goToRegister() {
      this.$router.push('/register');
    },
    goToForgotPassword() {
      this.$router.push('/forgot-password');
    },
  },
};
</script>

<style scoped>
/* Custom styling */
.text-red-500 {
  color: red;
}
</style>
