<template>
    <div class="flex justify-center items-center h-screen">
      <div class="flex flex-col gap-4 rounded-box bg-base-200 p-6 max-w-md w-full">
        <h1 class="text-3xl font-bold self-center">Forgot Password</h1>
        <p class="text-center">Please message us on Telegram at <strong>@PixelManagement</strong> to reset your password.</p>
        
        <button class="btn btn-primary self-center" @click="goToLogin">Back to Login</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      goToLogin() {
        this.$router.push('/login');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your custom styling */
  </style>
  